<template>
  <div class="papertest_warp">
    <COMMONTOPSEARCH ref="top_search_ref"
                     @initData="getTemplateist"
                     :showSearch="false" />
    <div class="papers">
      <div class="paper"
           v-for="(item,index) in templateList"
           @click="toPaperList(item)"
           :key="index">
        <div class="top"
             :style="{'background':`url(${tabBakcgroundImages[index]}) 0 0/100% 100%`}">
          <div class="subject"
               :style="{'background':subjecBacks[index]}">
            {{subject_name}}
          </div>
          <div class="template_name">{{item.paper_district_name}}</div>
        </div>
        <div class="bottom">
          选择试卷
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { tabBakcgroundImagesProperty, subjecBackProperty } from './property'
import { getUserSubject } from '@/api/subject.js'
import { getTemplate } from '@/api/template.js'
import COMMONTOPSEARCH from '@/views/components/COMMON_TOP_SEARCH/index.vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const tabBakcgroundImages = tabBakcgroundImagesProperty
const subjecBacks = subjecBackProperty
let subjectId = ref(0)
let templateList = ref([])
let subject_name = ref('')

let store = useStore()

onBeforeRouteLeave((to, from, next) => {
  if (to.name == '/index') {
    store.commit('CLEAR_ROUTE_CACHE')
  }
  next()
})

const getTemplateist = async (id, keyword, name) => {
  subject_name.value = name
  subjectId.value = id
  let params = {
    subject_id: id
  }
  const { data } = await getTemplate(params)
  templateList.value = data.list
}

const toPaperList = (row) => {
  router.push(`/testpaper/list?&subjectId=${subjectId.value}&id=${row.paper_district_id}`)
}

</script>

<style lang="scss" scoped>
.papertest_warp {
  background-image: url("../../static/allBackg/bg-整卷测试.png");
  background-repeat: no-repeat;
  width: 100vw;
  height: 100%;
  background-size: 100% 100%;
  overflow: hidden;
  .back {
    img {
      width: 60rem;
      height: 60rem;
      margin-top: 30rem;
      margin-left: 40rem;
      margin-bottom: 30rem;
    }
  }
  .subjectabs {
    position: relative;
    background: #3e8ef3;
    color: #e5e5e5;
    width: calc(100vw - 120px);
    margin: 0 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    border-radius: 33px;
    .item {
      margin-right: 40px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .search {
      position: absolute;
      right: 0px;
      width: 50px;
      height: 40px;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
.papers {
  display: flex;
  flex-wrap: wrap;
  // width: calc(100% - 3.125%);
  // width: 1800rem;
  // height: 700rem;
  height: calc(100vh - 290rem);
  padding: 40rem 30rem 40rem 0rem;

  background-color: #ffffff;
  border-radius: 35rem;
  box-shadow: 0px 0px 0px 0px rgba(229, 229, 229, 1);
  // margin: 0 auto;
  margin: 0 3.125%;
  margin-top: 40rem;
  align-content: flex-start;
  overflow-y: auto;
  .paper {
    position: relative;
    // width: 300rem;
    width: calc(20% - 40rem);
    min-width: 130px;
    height: 280rem;
    box-shadow: 0rem 2px 10rem 0rem rgba(165, 162, 162, 0.29);
    border-radius: 20rem;
    overflow: hidden;
    margin-left: 40rem;
    margin-bottom: 40rem;
    cursor: pointer;
    .top {
      height: 300rem;
      height: 200rem;
      display: flex;
      position: relative;
      justify-content: center;
      .subject {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 28rem;
        color: #ffffff;
        line-height: 54rem;
        padding: 7rem 47rem;
        height: 54rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20rem 0rem 20rem 0rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .template_name {
        font-weight: bold;
        font-size: 28rem;
        color: #ffffff;
        margin-top: 90rem;
      }
    }
    .bottom {
      height: 80rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28rem;
      font-weight: bold;
      color: #000000;
    }
  }
}
.active {
  color: white;
  font-weight: bold;
}
</style>